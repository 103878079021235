import _ from "lodash";
import moment from 'moment';

const Funciones = {
    formateaRut(rut) {
        var actual = rut.replace(/[^0-9kK]/g, "");

        if (actual != '' && actual.length > 1) {
            var sinPuntos = actual.replace(/\./g, "");
            var actualLimpio = sinPuntos.replace(/-/g, "");
            var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
            var rutPuntos = "";
            var i = 0;
            var j = 1;
            for (i = inicio.length - 1; i >= 0; i--) {
                var letra = inicio.charAt(i);
                rutPuntos = letra + rutPuntos;
                if (j % 3 == 0 && j <= inicio.length - 1) {
                    rutPuntos = "." + rutPuntos;
                }
                j++;
            }
            var dv = actualLimpio.substring(actualLimpio.length - 1);
            rutPuntos = rutPuntos + "-" + dv;
        }
        return rutPuntos;
    },
    Valida_Rut(rutcompleto) {
        var tmpstr = "";
        var intlargo = rutcompleto
        if (intlargo !== null && intlargo.length > 0) {
            let crut = rutcompleto
            let largo = crut.length;
            if (largo < 2) {
                return false;
            }
            for (let i = 0; i < crut.length; i++)
                if (crut.charAt(i) != ' ' && crut.charAt(i) != '.' && crut.charAt(i) != '-') {
                    tmpstr = tmpstr + crut.charAt(i);
                }
            let rut = tmpstr;
            crut = tmpstr;
            largo = crut.length;

            if (largo > 2)
                rut = crut.substring(0, largo - 1);
            else
                rut = crut.charAt(0);

            let dv = crut.charAt(largo - 1);

            if (rut == null || dv == null)
                return 0;

            var dvr = '0';
            let suma = 0;
            let mul = 2;

            for (let i = rut.length - 1; i >= 0; i--) {
                suma = suma + rut.charAt(i) * mul;
                if (mul == 7)
                    mul = 2;
                else
                    mul++;
            }

            let res = suma % 11;
            if (res == 1)
                dvr = 'k';
            else if (res == 0)
                dvr = '0';
            else {
                let dvi = 11 - res;
                dvr = dvi + "";
            }

            if (dvr != dv.toLowerCase()) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }
}

const Validaciones = {
    formData: null,
    reset(validaciones) {
        let self = this;
        Object.keys(validaciones).map(key => {
            let validacionCampo = validaciones[key];
            if ('error' in validacionCampo) {
                validacionCampo.error = false;
            } else {
                if (Array.isArray(validacionCampo)) {
                    // console.log('es un array');
                } else if (_.isPlainObject(validacionCampo)) {
                    self.reset(validacionCampo);
                }
            }
        });
    },
    hasError(validaciones) {
        let self = this;
        return Object.keys(validaciones).some(key => {
            let validacionCampo = validaciones[key];
            if ('error' in validacionCampo) {
                return validacionCampo.error == true;
            } else {
                return self.hasError(validacionCampo);
            }
        });
    },
    validar(values, validaciones, formKeyName = null, formIndex = null) {
        let self = this;
        if (self.formData == null) {
            self.formData = values;
        }
        Object.keys(values).map(key => {
            if (_.isPlainObject(values[key])) {
                console.log('hacer algo cuando es objeto');
            } else if (Array.isArray(values[key])) {
                values[key].map((arrayValue, index) => {
                    // console.log('arrayValue', arrayValue);
                    // console.log('index', index);
                    self.validar(arrayValue, validaciones, key, index);
                });
            } else {
                let validacion = self.buscarValidacion(key, validaciones, formKeyName, formIndex);
                if (validacion) {
                    console.log('validacion', validacion);

                    let cumpleRequires = false;
                    if ('requires' in validacion) {
                        if (_.isFunction(validacion.requires)) {
                            cumpleRequires = validacion.requires(self.formData);
                        }
                    } else {
                        cumpleRequires = true;
                    }

                    if (cumpleRequires) {
                        let rules = validacion.reglas.split("|");
                        let inputValido = rules.some(rule => {
                            let validado = false;
                            // let allowEmpty = rules.includes('allow-empty');
                            switch (rule) {
                                case 'rut':
                                    validado = Funciones.Valida_Rut(values[key]);
                                    break;
                                case 'not-empty':
                                    validado = (values[key]) ? true : false;
                                    break;
                                case 'email':
                                    validado = true;
                                    break;
                                case 'number':
                                    validado = isNaN(values[key]);
                                    break;
                                case 'date':
                                    var date = moment(values[key]);
                                    validado = date.isValid();
                                    break;
                            }
                            return validado;
                        });
                        validacion.error = !inputValido;
                    }
                }
            }
        });
    },
    buscarValidacion(campo, validaciones, keyName = null) {
        if (keyName == null) {
            if (_.get(validaciones, `${campo}.reglas`)) {
                return validaciones[campo];
            }
        } else {
            if (_.get(validaciones, `${keyName}.${campo}.reglas`)) {
                return validaciones[keyName][campo];
            }
        }
    }
};


export { Funciones, Validaciones };